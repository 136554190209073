const StringConstants = {
  BASIC: "BASIC",
  BASIC_CLASSIFIER: "The basic image classifier model is Google's Vision Transformer from Hugging Face, pre-trained on ImageNet. It recognizes 999 different animals and objects.",
  CLASSIFY: "Classify",
  CREATE_ACCOUNT: "Create account",
  CURRENTLY_TRAINING: "Your model is being trained...",
  CUSTOM: "CUSTOM",
  DELETE: "Delete",
  DONE: "Done",
  EMAIL_VERIFICATION_REQUIRED: "Email verification required",
  FEATURES: "Features",
  HOME: "Home",
  LOGIN: "Login",
  LOGOUT: "Logout",
  LOGOUT_DONE: "You have logged out.",
  MODEL_SELECTION: "Select a model:",
  MY_ACCOUNT: "My Account",
  ONE_MODEL: "To save resources, users are only allowed one customizable image classification model",
  RESEND_VERIFICATION_EMAIL: "Resend Verification Email",
  SERVER_DOWN: "Sorry, the server is currently down...",
  START_TRAINING: "Start training",
  TODO: "TODO",
  UPLOAD_AND_TRAIN: "UPLOAD & TRAIN",
  UNEXPECTED_ERROR: "Unexpected error occurred.",
  TRAIN: "TRAIN",
  TRAINING_TIME: "For a training set of 100 images, expect around 5 minutes of training time.",
  VERIFICATION_CODE: "Please enter the 6-digit verification code sent to your email",
  VERIFY: "Verify",
};

export default StringConstants;
